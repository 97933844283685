import { getSessionItem, KEYS } from '../store/storage';
import moment from 'moment';
import {
  getAutopilotCampaign,
  updateAutopilotCampaignSubscription,
  getUserSegments,
  getManualCampaigns,
  getManualCampaignsV2,
  saveManualCampaign,
  updateManualCampaign,
  getAutopilotCampaignTranscationData,
  getManualCampaignTranscationData,
  estimateManualCampaignCost,
  getCampaignAvailableBindings,
  updateAutopilotCampaign,
  getCampaignSetUsageCurrentMonth,
  checkCRMSubscribeStatus,
  confirmCRMNewUserAgreement,
  updateSpecAutopilotCampaignSubscription,
  getManualCampaignsV2ById,
  getTwilioStatus,
  queryTollFee,
  submitTollFee,
} from '../services/crm';

const BASE = 'CRM';

export const actions = {
  updateAutopilotCampaignList: `${BASE}_UPDATE_AUTOPILOT_CAMPAIGN_LIST`,
  updateUserSegmentList: `${BASE}_UPDATE_USER_SEGMENT_LIST`,
  updateManualCampaignList: `${BASE}_UPDATE_MANUAL_CAMPAIGN_LIST`,
  updateCampaignAvailableBindingList: `${BASE}_UPDATE_CAMPAIGN_AVAILABLE_BINDING_LIST`,
  updateSegmentMonthlyUsage: `${BASE}_UPDATE_SEGMENTS_USAGE`,
  confirmCampaignServiceUsage: `${BASE}_CONFIRM_CAMPAIGN_SERVICE_USAGE`,
  loadCRMSubscribeStatus: `${BASE}_LOAD_CAMPAIGN_SERVICE_SUBSCRIBE_STATUS`,
  loadTwilioStatus: `${BASE}_LOAD_TWILIO_STATUS`,
  loadTwilioApplyInfo: `${BASE}_LOAD_TWILIO_APPLY_INFO`,
  updateTwilioApplyInfo: `${BASE}_UPDATE_TWILIO_APPLY_INFO`,
  updateTwilioRequestCount: `${BASE}_UPDATE_TWILIO_REQUEST_COUNT`,
};

export const loadTwilioApplyInfoAndTwilioStatus = (callback) => async (dispatch, getState) => {
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response = [];
  try {
    response = await Promise.all([
      queryTollFee(),
      getTwilioStatus({
        restaurant_id,
      }),
    ]);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
  if (response?.success === false) return;

  let twilioApplyInfo = response[0]?.data;
  let twilioStatus = response[1]?.data?.data?.braze_to_twilio || false;

  if (twilioApplyInfo?.profile?.business_address?.country === '') {
    twilioApplyInfo.profile.business_address.country = undefined;
  }
  if (twilioApplyInfo?.profile?.business_address?.state_province_region === '') {
    twilioApplyInfo.profile.business_address.state_province_region = undefined;
  }

  dispatch({
    type: actions.updateTwilioApplyInfo,
    payload: twilioApplyInfo,
  });
  dispatch({
    type: actions.loadTwilioStatus,
    payload: twilioStatus,
  });
};

export const loadTwilioApplyInfo = (callback) => async (dispatch, getState) => {
  callback &&
    callback({
      isLoading: true,
    });
  let response;
  try {
    response = await queryTollFee();
  } catch (e) {
    response = { success: false };
  }
  callback &&
    callback({
      isLoading: false,
    });
  if (!response.success) return;

  let twilioApplyInfo = response?.data;
  dispatch({
    type: actions.updateTwilioApplyInfo,
    payload: twilioApplyInfo,
  });
};

export const loadTwilioStatus = (callback) => async (dispatch, getState) => {
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  callback &&
    callback({
      isLoading: true,
    });
  let response;
  try {
    response = await getTwilioStatus({
      restaurant_id,
    });
  } catch (e) {
    response = { success: false };
  }
  callback &&
    callback({
      isLoading: false,
    });
  if (!response.success) return;

  let twilioStatus = response?.data?.data?.braze_to_twilio || false;
  dispatch({
    type: actions.loadTwilioStatus,
    payload: twilioStatus,
  });
};

export const loadCRMAutopilotCampaignList = () => async (dispatch, getState) => {
  const { autopilotCampaignList } = getState().crm;
  // if (autopilotCampaignList) return;

  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await getAutopilotCampaign({ locale, restaurant_id });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;
  dispatch({
    type: actions.updateAutopilotCampaignList,
    payload: response.data,
  });
};

export const updateTwilioApplyInfo = (newTwilioProfile, callback, callbackTwo) => async (dispatch, getState) => {
  let response;
  callback &&
    callback({
      isSaving: true,
    });
  try {
    response = await submitTollFee(newTwilioProfile);
  } catch (e) {
    response = { success: false };
  }
  callback &&
    callback({
      isSaving: false,
    });
  callbackTwo && callbackTwo(response);
  if (!response.success) return;
  let twilioApplyInfo = response?.data;
  dispatch({
    type: actions.updateTwilioApplyInfo,
    payload: twilioApplyInfo,
  });
};

export const updateCRMAutopilotCampaignSubscribe =
  (newCampaignSubscribeStatus, callback) => async (dispatch, getState) => {
    const locale = getSessionItem(KEYS.locale);
    const restaurant_id = getSessionItem(KEYS.restaurantId);
    let response;
    try {
      response = await updateAutopilotCampaignSubscription({ locale, restaurant_id, newCampaignSubscribeStatus });
    } catch (e) {
      response = { success: false };
    }

    typeof callback === 'function' && callback(response);
    if (!response.success) return;
    dispatch({
      type: actions.updateAutopilotCampaignList,
      payload: response.data,
    });
  };

export const loadCRMUserSegments = (callback) => async (dispatch, getState) => {
  const { userSegments } = getState().crm;
  if (userSegments) return;

  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await getUserSegments({ locale, restaurant_id });
  } catch (e) {
    response = { success: false };
  }
  typeof callback === 'function' && callback(response);
  if (!response.success) return;
  dispatch({
    type: actions.updateUserSegmentList,
    payload: response.data,
  });
};

export const loadCRMManualCampaigns = (queryObject, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    // response = await getManualCampaigns({ locale, restaurant_id });
    let objectObj = {
      search_name: queryObject.search_name || '',
      segment_ids: queryObject.segment_ids || '',
      message_types: queryObject.campaign_types || '',
      document_status: queryObject.document_status || '',
      order_by: queryObject.order_by || '',
      page: queryObject.page || 1,
      page_size: queryObject.page_size || 10,
    };
    response = await getManualCampaignsV2({ locale, restaurant_id, ...objectObj });
  } catch (e) {
    response = { success: false };
  }
  typeof callback === 'function' && callback(response);
  if (!response.success) return;
  dispatch({
    type: actions.updateManualCampaignList,
    payload: response.data,
  });
};

export const loadCRMManualCampaignById = (campaignId, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);

  let response;
  try {
    response = await getManualCampaignsV2ById({ locale, campaignId, restaurant_id });
  } catch (e) {
    response = { success: false };
  }
  typeof callback === 'function' && callback(response);
};

export const saveCRMManualCampaign = (newCampaign, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await saveManualCampaign({
      locale,
      newCampaign,
      restaurant_id,
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response);
  if (!response.success) return;
};
export const updateCRMManualCampaign = (updateCampaign, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await updateManualCampaign({
      locale,
      updateCampaign,
      restaurant_id,
    });
  } catch (e) {
    response = { success: false };
  }

  callback(response);
  if (!response.success) return;
};

export const loadCRMAutopilotCampaignTranscationData =
  ({ start_at, end_at }, callback) =>
  async (dispatch, getState) => {
    const locale = getSessionItem(KEYS.locale);
    const restaurant_id = getSessionItem(KEYS.restaurantId);
    let response;
    try {
      response = await getAutopilotCampaignTranscationData({
        locale,
        restaurant_id,
        start_at: start_at,
        end_at: end_at,
      });
    } catch (e) {
      response = { success: false };
    }

    callback(response);
    if (!response.success) return;
  };

export const loadCRMManualCampaignTranscationData =
  ({ start_at, end_at }, callback) =>
  async (dispatch, getState) => {
    const locale = getSessionItem(KEYS.locale);
    const restaurant_id = getSessionItem(KEYS.restaurantId);
    let response;
    try {
      response = await getManualCampaignTranscationData({
        locale,
        restaurant_id,
        start_at: start_at,
        end_at: end_at,
      });
    } catch (e) {
      response = { success: false };
    }

    callback(response);
    if (!response.success) return;
  };

export const getManualCampaignEstimateCost =
  (userSegmentId, campaignContentComposite, expiredDateRange, callback) => async (dispatch, getState) => {
    const locale = getSessionItem(KEYS.locale);
    const restaurant_id = getSessionItem(KEYS.restaurantId);
    const { foreignCampaignContent, campaignContent } = campaignContentComposite;
    let response;
    try {
      response = await estimateManualCampaignCost({
        locale,
        userSegmentId,
        restaurant_id,
        // campaignContent,
        foreignCampaignContent,
        campaignContent,
        expiredDateRange: expiredDateRange,
      });
    } catch (e) {
      response = { success: false };
    }

    callback(response);
    if (!response.success) return;
  };

export const loadCRMCampaignAvailableBindings = () => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await getCampaignAvailableBindings({ locale, restaurant_id });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  dispatch({
    type: actions.updateCampaignAvailableBindingList,
    payload: (response.data.data || []).map((item) => {
      return {
        id: item,
        value: item,
      };
    }),
  });
};

export const updateCRMAutopilotCampaign = (updateCampaign, callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    let [updateAutopilotCampaignResponse, updateSpecAutopilotCampaignSubscriptionResponse] = await Promise.all([
      updateAutopilotCampaign({
        locale,
        updateCampaign,
        restaurant_id,
      }),
      updateSpecAutopilotCampaignSubscription({
        locale,
        restaurant_id,
        updateCampaign,
      }),
    ]);
    if (updateAutopilotCampaignResponse.success && updateSpecAutopilotCampaignSubscriptionResponse.success) {
      response = { success: true };
    } else {
      response = { success: false };
    }
  } catch (e) {
    response = { success: false };
  }

  callback(response);
  if (!response.success) return;
};

export const loadCRMCampaignSetUsageCurrentMonth = (callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await getCampaignSetUsageCurrentMonth({
      restaurant_id,
    });
  } catch (e) {
    response = { success: false };
  }
  if (!response.success) return;

  dispatch({
    type: actions.updateSegmentMonthlyUsage,
    payload: response.data.data,
  });
};

export const confirmLoyaltyCampaignServiceUsage = (callback) => async (dispatch, getState) => {
  dispatch({
    type: actions.confirmCampaignServiceUsage,
    payload: true,
  });
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  let response;
  try {
    response = await confirmCRMNewUserAgreement({ restaurant_id });
  } catch (e) {
    response = { success: false };
  }
  callback && callback(response);

  if (!response.success) return;

  dispatch({
    type: actions.confirmCampaignServiceUsage,
    payload: true,
  });
};

export const loadCRMSubscribeStatus = (callback) => async (dispatch, getState) => {
  const locale = getSessionItem(KEYS.locale);
  const { crmSubscribeStatus } = getState().crm;
  const restaurant_id = getSessionItem(KEYS.restaurantId);
  callback &&
    callback({
      isLoading: true,
    });
  let response;
  try {
    response = await checkCRMSubscribeStatus({
      restaurant_id,
    });
  } catch (e) {
    response = { success: false };
  }
  callback &&
    callback({
      isLoading: false,
    });
  if (!response.success) return;

  let subscribed = response?.data?.data?.enabled || false;
  dispatch({
    type: actions.loadCRMSubscribeStatus,
    payload: subscribed,
  });
};
