import { GET, PUT, POST } from '../utils/request';
import { CLIENT_ID, LOYALTY_API_PREFIX } from '../consts';
import { SMS_SERVICE_API_PREFIX } from 'src/consts/third_party';

export async function getAutopilotCampaign({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/system_campaigns`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function subscribeAutopilotCampaign({ locale, restaurant_id, subscribedCampaignIds }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/crm/v1/system_campaigns/subscribe`,
    {
      restaurant_id: restaurant_id,
      campaign_ids: subscribedCampaignIds.join(','),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}
export async function unsubscribeAutopilotCampaign({ locale, restaurant_id, unSubscribedCampaignIds }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/crm/v1/system_campaigns/unsubscribe`,
    {
      restaurant_id: restaurant_id,
      campaign_ids: unSubscribedCampaignIds.join(','),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateAutopilotCampaignSubscription({ locale, restaurant_id, newCampaignSubscribeStatus }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/crm/v1/system_campaigns/update_subscribe_status`,
    {
      restaurant_id: restaurant_id,
      subscribed: newCampaignSubscribeStatus.subscribed.join(','),
      unsubscribed: newCampaignSubscribeStatus.unSubscribed.join(','),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getTwilioStatus({ restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/dashboards/twilio_status`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}
export async function getUserSegments({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/user_segments`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
      silence: false,
    }
  );
}

export async function getManualCampaigns({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/restaurant_campaigns`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
      silence: false,
    }
  );
}

export async function getManualCampaignsV2({
  locale,
  restaurant_id,
  search_name,
  segment_ids,
  message_types,
  document_status,
  order_by,
  page,
  page_size,
}) {
  let queryObject = {
    restaurant_id: restaurant_id,
    page: page || 1,
    per_page: page_size || 10,
    ...(search_name
      ? {
          keyword: search_name,
        }
      : {}),
    ...(segment_ids
      ? {
          segment_id: segment_ids,
        }
      : {}),
    ...(message_types
      ? {
          message_type: message_types,
        }
      : {}),
    ...(document_status
      ? {
          document_status,
        }
      : {}),
    //order_by: "message_type asc, segment_id desc, status asc" #free combination,
    ...(order_by
      ? {
          order_by,
        }
      : {}),
  };
  return GET(`${LOYALTY_API_PREFIX}/api/crm/v2/restaurant_campaigns`, queryObject, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
    parseAsJSONApi: true,
    silence: false,
  });
}

export async function getManualCampaignsV2ById({ locale, restaurant_id, campaignId }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/restaurant_campaigns/${campaignId}`,
    {
      restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
      silence: false,
    }
  );
}

export async function saveManualCampaign({ locale, newCampaign, restaurant_id }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/crm/v1/restaurant_campaigns`,
    {
      restaurant_id: restaurant_id,
      restaurant_campaign: newCampaign,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateManualCampaign({ locale, updateCampaign, restaurant_id }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/crm/v1/restaurant_campaigns/${updateCampaign.id}`,
    {
      restaurant_id: restaurant_id,
      restaurant_campaign: updateCampaign,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getAutopilotCampaignTranscationData({ start_at, end_at, locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/dashboards/system_campaigns`,
    {
      restaurant_id: restaurant_id,
      start_at: start_at,
      end_at: end_at,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export async function getManualCampaignTranscationData({ start_at, end_at, locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/dashboards/restaurant_campaigns`,
    {
      restaurant_id: restaurant_id,
      start_at: start_at,
      end_at: end_at,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export async function estimateManualCampaignCost({
  locale,
  userSegmentId,
  foreignCampaignContent,
  campaignContent,
  restaurant_id,
  expiredDateRange,
}) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/crm/v1/restaurant_campaigns/calculate_sms_bill`,
    userSegmentId
      ? {
          restaurant_id: restaurant_id,
          segment_id: userSegmentId,
          en_content: campaignContent || '',
          cn_content: foreignCampaignContent || '',
          ...(expiredDateRange
            ? {
                days_lower_limit: expiredDateRange.days_threshold_from,
                days_upper_limit: expiredDateRange.days_threshold_to,
              }
            : {}),
        }
      : {
          restaurant_id: restaurant_id,
          en_content: campaignContent || '',
          cn_content: foreignCampaignContent || '',
        },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export async function getCampaignAvailableBindings({ restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/system_campaigns/available_bindings`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export async function updateAutopilotCampaign({ locale, updateCampaign, restaurant_id }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/crm/v1/restaurant_customized_system_campaigns`,
    {
      restaurant_id: restaurant_id,
      system_campaign_id: updateCampaign.id,
      message: updateCampaign.content,
      foreign_message: updateCampaign.foreign_content,
      subscribed: updateCampaign.subscribed,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export async function getCampaignSetUsageCurrentMonth({ locale, restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/dashboards/segments_sent_count`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

//当前餐厅CRMcampaign 的订阅状态
export async function checkCRMSubscribeStatus({ restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/crm/v1/dashboards/crm_status`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

//同意当前餐厅的确认订阅新的CRM协议
export async function confirmCRMNewUserAgreement({ restaurant_id }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/crm/v1/dashboards/confirm_user_agreement`,
    {
      restaurant_id: restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export async function updateSpecAutopilotCampaignSubscription({ locale, restaurant_id, updateCampaign }) {
  return PUT(
    `${LOYALTY_API_PREFIX}/api/crm/v1/system_campaigns/${updateCampaign.id}/update_subscribe_status`,
    {
      restaurant_id: restaurant_id,
      subscribed: updateCampaign.subscribed,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function queryTollFee() {
  return GET(`${SMS_SERVICE_API_PREFIX}/api/v1/onboarding/toll-free`);
}

export function submitTollFee(profile) {
  return POST(`${SMS_SERVICE_API_PREFIX}/api/v1/onboarding/toll-free`, profile);
}

export function queryTollFeeHistory() {
  return GET(`${SMS_SERVICE_API_PREFIX}/api/v1/onboarding/toll-free/history`);
}
